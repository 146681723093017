import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container, Form } from "react-bootstrap";
import { allRFIDAction, fetchSingleRfidApi, updateRfidAction } from "../../../../store/actions/customerActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import "../evse.css";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { allEvseAction } from '../../../../store/actions/evseAction';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';
import Select from 'react-select';
import moment from 'moment';

const UpdateRFID = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [isAmountBased, setIsAmountBased] = useState(false);
    const [isRechargeAmountFree, setIsRechargeAmountFree] = useState(false);
    const [iskWhBased, setIskWhBased] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [rfidRechargeAmount, setRfidRechargeAmount] = useState('');
    const [creditDebitAmount, setCreditDebitAmount] = useState('');
    const [kWhUsage, setkWhUsage] = useState('');
    const [kWhAmount, setKWhAmount] = useState('');
    const [isTimeBased, setIsTimeBased] = useState(false);
    const [time, setTime] = useState('');
    const [timeRechargeAmount, setTimeRechargeAmount] = useState('');
    const [rfidType, setRfidType] = useState('');
    const [postpaidDuration, setPostpaidDuration] = useState('');
    const [isPaymentCollectedOffline, setIsPaymentCollectedOffline] = useState(false);


    useEffect(() => {
        props.getAllCurrency();
        props.all();
        props.allEvse();
        props.getSingleRfidData(id);
    }, [id]);

    const rfidSchema = Yup.object().shape({
        rfid: Yup.string(),
        commission: Yup.string(),
        currency: Yup.string().required('Currency is required'),
        station_name: Yup.array()
            .min(1, 'Please select at least one station name.')  // Require at least one selection
            .of(Yup.string().required('A station name is required.')),
    });

    const { register, handleSubmit, setValue, getValues, reset, control, watch, formState: { errors } } = useForm({ resolver: yupResolver(rfidSchema) });

    const data = props.singleRfidRes && props.singleRfidRes.rfids[0] ? props.singleRfidRes.rfids[0] : {};
    // console.log("data", data);

    useEffect(() => {
        if (data) {
            setValue('rfid', data.rfid);
            setValue('commission', data.commission);
            setValue('currency', data.currency);
            if (data.station && data.station === 'All') {
                // console.log("All station is here...")
                setValue('station_name', [data.station]);
            } else if (data.station && data.station !== 'All') {
                // console.log("Except All,All stations is here...")
                setValue('station_name', [data.station]);
            } else {
                setValue('station_name', data.rfid_stations);
            }
            setIsAmountBased(data.amount_based);
            setIsRechargeAmountFree(data.free_charging);
            setRfidRechargeAmount(data.amount);
            setIskWhBased(data.kwh_based);
            setkWhUsage(data.kwh);
            setKWhAmount(data.kwh_amount);
            setIsTimeBased(data.time_based);
            setTime(data.time);
            setRfidType(data.type);
            setPostpaidDuration(data.sub_duration);
            setTimeRechargeAmount(data.time_amount)
            setTotalAmount(data.amount)
            setIsPaymentCollectedOffline((data.offline_payment))
        }
    }, [data, setValue]);
    const stationWatch = watch('station_name');
    // console.log(watch('station_name'))
    // console.log(isPaymentCollectedOffline)

    const onSubmit = async data => {
        const submissionData = {
            ...data,
            isAmountBased: isAmountBased,
            isRfidFreeCharger: isRechargeAmountFree,
            amount: isAmountBased ? totalAmount : '',
            iskWhBased: iskWhBased,
            kWhUsage: iskWhBased ? kWhUsage : '',
            kWhAmount: kWhAmount,
            isTimeBased: isTimeBased,
            time: isTimeBased ? time : '',
            timeRechargeAmount: timeRechargeAmount,
            rfidType: rfidType,
            postpaidDuration: postpaidDuration,
            isPaymentCollectedOffline: isRechargeAmountFree === false ? (isPaymentCollectedOffline === true) : false,
        };
        // console.log("onSubmit data", submissionData, id);
        props.update(submissionData, id);
    };

    const handleFreeCharge = () => {
        setTotalAmount('0')
        setIsRechargeAmountFree(!isRechargeAmountFree);
        if (!isRechargeAmountFree) {
            setRfidRechargeAmount('0');
            setCreditDebitAmount('0')
            setTotalAmount('0')
            // setIsPaymentCollectedOffline(false)
        }
    }

    const HandleAmountBasedKeyPress = (event) => {
        // console.log("event", event);
        const { key, target: { value } } = event;

        if (
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Tab'
        ) {
            return;
        }

        if (key === '-' && value === '') {
            return;
        }
        if (key === '.' && value === '') {
            event.preventDefault();
            // return;
        }
        if (key === '.' && value.includes('.')) {
            event.preventDefault();
            return;
        }

        // Prevent entering '0' initially or if the value is empty
        if (key === '0' && value === '') {
            event.preventDefault();
        }

        // Allow numbers and decimal point
        if (!/[0-9.]/.test(key)) {
            event.preventDefault();
        }
    };

    const handleCreditDebitAmountChange = (e) => {
        setCreditDebitAmount(e.target.value);
        const rechargeAmount = parseFloat(rfidRechargeAmount) || 0;
        const creditDebit = parseFloat(e.target.value) || 0;
        setTotalAmount((rechargeAmount + creditDebit).toFixed(0))
    };

    const handleRfidTypeChange = (e) => {
        setPostpaidDuration('');
        const value = e.target.value;
        setRfidType(value);
    };

    const handlePostpaidDuration = (e) => {
        const value = e.target.value;
        setPostpaidDuration(value);
    };

    const handleIsCollectedOffline = (event) => {
        const value = event.target.value === "true";
        setIsPaymentCollectedOffline(value);
        // console.log("Is payment collected offline?", value);
    };

    const isSubmitDisabled = () => {
        if (iskWhBased && !kWhUsage) {
            return true;
        }
        if (isTimeBased && (!time || !timeRechargeAmount)) {
            return true;
        }
        if (!rfidType) {
            return true;
        }
        if (rfidType === 'postpaid' && !postpaidDuration) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (props.updateresponse.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('RFID has been updated successfully.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateresponse]);
    // console.log(props.updateresponse)

    if (redirect) {
        return <Redirect to='/dashboard/view_rfid_pin' />;
    }

    const handleCurrency = (e) => {
        const code = e.value;
        // console.log("currency", code);
        setValue("currency", code);
    };

    const currencyOptions = props.allcurrency && props.allcurrency.currency?.map(item => ({
        value: item.currency_code,
        label: item.currency_code
    }));

    const stationNameOptions = [
        { value: 'All', label: 'All' }, // Static option
        ...(props.evseList && props.evseList.data ? props.evseList.data
            .filter((item) => item.status !== 'Discovery' && item.publish !== false)
            .map(item => ({
                value: item.name,
                label: item.name
            }))
            : []) // Dynamic options
    ];

    const stationOptions = stationWatch?.map(station => ({
        value: station,
        label: station
    }));

    // console.log("staion", props.evseList && props.evseList.data)
    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
                            <b>{t("UPDATE RFID")}</b>
                        </Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>{t("RFID")} <span className='mandatory-field'>*</span></label>
                                    <input disabled type="text" className={`form-control gray-border`} name="rfid" id="floatingInputGridrfid" placeholder={t("RFID")} {...register("rfid")} />
                                </div>
                                <br />

                                <div className="form-floating">
                                    <label>{t("Date")}</label>
                                    <input disabled type="text" className={`form-control gray-border`} name="commission" id="floatingInputGridName" placeholder={'Commission Date'} {...register('commission')} />
                                </div>
                                <br />

                                <Row className="row d-flex align-items-center">
                                    <Col lg={4} md={12} className='col-md'>
                                        <div className="mr-4">
                                            <input className="form-check-input ml-0" type="checkbox" id="amountBasedCheckbox" checked={isAmountBased} disabled />
                                            <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                {t("Amount Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={12} className='col-md'>
                                        <div className="">
                                            <input className="form-check-input ml-0" type="checkbox" id="kiloWattBasedCheckbox" checked={iskWhBased} disabled />
                                            <label className="form-check-label" htmlFor="kiloWattBasedCheckbox">
                                                {t("kWh Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={12} className='col-md'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="timeBasedCheckbox" checked={isTimeBased} disabled />
                                            <label className="form-check-label" htmlFor="timeBasedCheckbox">
                                                {t("Time Based")}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <br />

                                {isAmountBased && (
                                    <>
                                        <div className=' pl-0 mb-2'>
                                            <div className="">
                                                <input className="form-check-input" style={{ width: "25rem" }} type="checkbox" id="amountBasedCheckbox" checked={isRechargeAmountFree} onChange={handleFreeCharge} />
                                                <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                    {t("IsFreeCharge?")}
                                                </label>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("RFID Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border`} name="rfidRechargeAmount" id="floatingInputRfidRechargeAmount" placeholder={t("RFID Recharge Amount")} value={rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Credit/Debit Amount")}</label>
                                                    <input type="number"
                                                        className={`form-control gray-border`}
                                                        name="creditDebitAmount"
                                                        id="floatingInputCreditDebitAmount"
                                                        placeholder={t("Credit/Debit Amount")}
                                                        disabled={isRechargeAmountFree}
                                                        value={creditDebitAmount}
                                                        onKeyDown={HandleAmountBasedKeyPress}
                                                        onChange={handleCreditDebitAmountChange}
                                                    />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Total Amount")}</label>
                                                    <input type="text" className={`form-control gray-border`} name="totalAmount" id="floatingInputTotalAmount" placeholder={t("Total Amount")} value={totalAmount || rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                        </Row>
                                        {!isRechargeAmountFree &&
                                            <div className='row'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} disabled={isRechargeAmountFree} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="" hidden>
                                                                {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                            </option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {isPaymentCollectedOffline === null && (
                                                            <div className="invalid-feedback">{"This field is required"}</div>
                                                        )}
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}

                                {iskWhBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("kWh Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border ${!kWhUsage && 'is-invalid'}`}
                                                        name="kiloWattUsage"
                                                        id="floatingInputKiloWattUsage"
                                                        placeholder={t("kWh Usage")}
                                                        value={kWhUsage}
                                                        onChange={(e) => setkWhUsage(e.target.value)}
                                                        onKeyPress={HandleKeyPress}
                                                    />
                                                    {!kWhUsage && <small className="text-danger">{t("kWh Usage is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border ${!kWhAmount && 'is-invalid'}`}
                                                            name="kWhAmount"
                                                            id="floatingInputkWhAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={kWhAmount}
                                                            onChange={(e) => setKWhAmount(e.target.value)}
                                                            onKeyPress={HandleKeyPress}

                                                        />
                                                        {!kWhAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="" hidden>
                                                            {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                        </option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {isPaymentCollectedOffline === null && (
                                                        <div className="invalid-feedback">{"This field is required"}</div>
                                                    )}
                                                </div>
                                                <br />
                                            </div>
                                        </div>

                                    </>
                                )}

                                {isTimeBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Time")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!time && 'is-invalid'}`}
                                                        name="time" id="floatingInputTime"
                                                        placeholder={t("No. of Hours")}
                                                        value={time}
                                                        onChange={(e) => setTime(e.target.value)}
                                                        onKeyPress={HandleKeyPress}

                                                    />
                                                    <small className="form-text text-muted">
                                                        {t("No. Of Hours")}
                                                    </small>
                                                    {!time && <small className="text-danger">{t("Time is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border ${!timeRechargeAmount && 'is-invalid'}`}
                                                            name="timeRechargeAmount"
                                                            id="floatingInputRechargeAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={timeRechargeAmount}
                                                            onChange={(e) => setTimeRechargeAmount(e.target.value)}
                                                            onKeyPress={HandleKeyPress}

                                                        />
                                                        {!timeRechargeAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="" hidden>
                                                            {isPaymentCollectedOffline === true ? "Yes" : isPaymentCollectedOffline === false ? "No" : "Select an option"}
                                                        </option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {isPaymentCollectedOffline === null && (
                                                        <div className="invalid-feedback">{"This field is required"}</div>
                                                    )}
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='row'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Tariff Currency")} <span className='mandatory-field'>*</span></label>
                                            <Select
                                                options={currencyOptions ? currencyOptions : []}
                                                className={`${errors.currency ? 'is-invalid' : ''}`}
                                                name="currency"
                                                id="floatingInputGridcurrency"
                                                placeholder={getValues("currency") ? getValues("currency") : "Select Currency"}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'grey',
                                                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                                        minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                    }),
                                                    valueContainer: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                                        padding: '0 0.75rem',
                                                    }),
                                                    placeholder: (baseStyles) => ({
                                                        ...baseStyles,
                                                        whiteSpace: 'nowrap',
                                                    }),
                                                    input: base => ({
                                                        ...base,
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        '& input': {
                                                            border: 'none !important',
                                                        },
                                                    }),

                                                }}
                                                onChange={handleCurrency}
                                            />
                                            <div className="invalid-feedback">{errors.currency?.message}</div>
                                        </div><br />
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Select Station Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <Controller
                                                name="station_name"
                                                control={control}
                                                className={`${errors.station_name ? 'is-invalid' : ''}`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        options={stationNameOptions}
                                                        isMulti
                                                        placeholder="Select Station Name"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : 'grey',
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                            }),
                                                            placeholder: (baseStyles) => ({
                                                                ...baseStyles,
                                                                whiteSpace: 'nowrap',
                                                            }),
                                                            input: base => ({
                                                                ...base,
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                '& input': {
                                                                    border: 'none !important',
                                                                },
                                                            }),
                                                        }}
                                                        onChange={(selectedOptions) => {
                                                            onChange(selectedOptions ? selectedOptions.map(option => option?.value) : []);  // Update form value
                                                        }}
                                                        value={stationOptions}
                                                        isOptionDisabled={(option) => {
                                                            const isAnyStationSelected = value && value.length > 0 && !value.includes('All');
                                                            const isAllSelected = value && value.includes('All');

                                                            // Disable "All" if any other station is selected
                                                            if (option.value === 'All') {
                                                                return isAnyStationSelected;
                                                            }

                                                            // Disable all other options if "All" is selected
                                                            return isAllSelected;
                                                        }}
                                                    />
                                                )}
                                            />

                                            {/* Display validation error message */}
                                            {errors.station_name && (
                                                <div className="invalid-feedback">
                                                    {errors.station_name.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                {!isRechargeAmountFree &&
                                    <div className='row'>
                                        <div className='col-md'>
                                            <div className="form-floating">
                                                <label>{t("RFID Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control`} name="rfidType" id="floatingSelectGridCurrency" disabled defaultValue={rfidType} onChange={(e) => handleRfidTypeChange(e)} >
                                                    <option value="" hidden disabled>{`${rfidType ? rfidType : t("Select RFID Type")}`}</option>
                                                    <option value="prepaid">{t("Prepaid")}</option>
                                                    <option value="postpaid">{t("Postpaid")}</option>
                                                </select>
                                                {rfidType === '' && <div className="invalid-feedback">{"RFID Type is required"}</div>}
                                            </div><br />
                                        </div>
                                        <div className={`${rfidType === 'postpaid' ? "col-md" : "col-12"}`}>
                                            {rfidType === 'postpaid' &&
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Postpaid Duration")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select form-control`}
                                                        name={`postpaidDuration`}
                                                        id="floatingSelectGridsubscriptionDuration"
                                                        onChange={(e) => handlePostpaidDuration(e)}
                                                        disabled
                                                    >

                                                        <option value="" hidden>
                                                            {`${postpaidDuration ? postpaidDuration.charAt(0).toUpperCase() + postpaidDuration.slice(1) : t("Select Postpaid Duration")}`}
                                                        </option>
                                                        <option value={"weekly"}>{t("Weekly")}</option>
                                                        <option value={"monthly"}>{t("Monthly")}</option>
                                                        <option value={"yearly"}>{t("Yearly")}</option>
                                                    </select>
                                                    {postpaidDuration === '' && <div className="invalid-feedback">{"Postpaid Duration is required"}</div>}

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                <Row className='mt-3 d-flex justify-content-end'>
                                    <Col lg={6} sm={12} className='d-flex justify-content-end'>
                                        <button className="lgn-btn btn ml-4" type="submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitDisabled()}>{t("UPDATE RFID")}</button>
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        updateresponse: state.customer.updateRfid,
        allcurrency: state.tariff.fetchcurrency,
        list: state.customer.fetchAllRFID,
        evseList: state.evse.allevse,
        singleRfidRes: state.customer.fetchSingleRfid,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allRFIDAction()),
        update: (data, id) => dispatch(updateRfidAction(data, id)),
        getAllCurrency: () => dispatch(allCurrencyAction()),
        allEvse: () => dispatch(allEvseAction()),
        getSingleRfidData: (id) => dispatch(fetchSingleRfidApi(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRFID);
