import React from 'react'
import PureBreadcrumbs from "../breadcrums";
import { useEffect, useRef, useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { fetchChargingStatusAction, fetchCo2CountInsightAction, fetchEVSEStatusAction, insightPercentageChange } from '../../../store/actions/insightAction';
import { connect } from "react-redux";
import "./insightCard.css"
import ChargerStatus from "./chargerStats/chargerStats";
import EnergyVSRevenueGraph from "./energyrevenue/energyrevenuegraph";
import CityGraph from "./cityGraph/cityGraph";
import SessionTypeGraph from "./sessiontypegraph/sessionTypeGraph";
import SessionGraph from "./sessionsgraph/sessionGraph";
import StationGraph from "./stationGraph/stationGraph";
import { fetchTop10FailedTransaction, fetchTop10SuccessTransaction, fetchTop10Transaction } from '../../../store/actions/reportActions';
import { useMemo } from 'react';
import InsightTable from './insightTable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

function Insight(props) {
    const { name, role, organisation } = JSON.parse(localStorage.getItem('user'))
    const HeaderName = 'Name';
    // Geocode.setApiKey(process.env.CURRENCY)
    const currency = process.env.REACT_APP_CURRENCY;
    // console.log(currency)

    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 3600 * 1000 * 720)
    );
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 3600 * 1000 * 24)
    );
    // console.log(startDate, endDate)
    //   const [org, setOrg] = useState("All");

    useEffect(() => {
        // console.log("Insighe first");
        props.getTotalCharging();
        props.getEvseStatus();
        props.fetcPercentageChange();
        props.list();
        props.top10FailedList();
        props.top10SuccessList();
        props.fetchNewCo2Count();
    }, []);

    const data = props.all && props.all.data;
    const failedTransactionData = props.latestFailedList ? props.latestFailedList.data : {};
    const successTransactionData = props.latestSuccessList ? props.latestSuccessList.data : {};
    const co2Data = props.fetchCo2Res && props.fetchCo2Res.total_co2;

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                id: "start_date",
                accessor: "start_date",
            },
            { Header: HeaderName, accessor: "customer_name" },
            { Header: "City", accessor: "city" },
            { Header: "Charging time (HH:MM:SS)", accessor: "consume_time" },
            { Header: "Total kWh", accessor: "kwh" },
            { Header: "Total Amount", accessor: "total_amount" },
            { Header: "Status", accessor: "status" },
        ],
        []
    );

    const failedTransactionColumns = useMemo(
        () => [
            { Header: "ID", accessor: "charging_id", id: "charging_idd" },
            {
                Header: 'Date',
                accessor: 'createat',
                Cell: ({ value }) => {
                    // Use 'value' directly from the accessor
                    return moment(value).local().format("DD-MM-YYYY");
                },
            },
            { Header: "Name", accessor: "customer_name" },
            { Header: "Station Name", accessor: "stationid" },
            { Header: "ChargeBox ID", accessor: "evse_id" },
            { Header: "Connector ID", accessor: "portno" },
            { Header: "Status", accessor: "status" },
            { Header: "Reason", accessor: "reason" },
            { Header: "Description", accessor: "description" },
        ], []
    );

    const successTransactionReport = useMemo(
        () => [
            { Header: "ID", accessor: "charging_id", id: "charging_idd" },
            {
                Header: 'Date',
                accessor: 'createat',
                Cell: ({ value }) => {
                    // Use 'value' directly from the accessor
                    return moment(value).local().format("DD-MM-YYYY");
                },
            },
            { Header: "Name", accessor: "customer_name" },
            { Header: "Station Name", accessor: "stationid" },
            { Header: "ChargeBox ID", accessor: "evse_id" },
            { Header: "Connector ID", accessor: "portno" },
            { Header: "Status", accessor: "status" },
            { Header: "Reason", accessor: "reason" },
            { Header: "Description", accessor: "description" },
        ], []
    );

    const handleAddClick = () => {
        window.location.reload(false);
        props.getEvseStatus();
    };

    const totalCo2 = parseFloat(props.total_sesion && props.total_sesion.total_co2).toFixed(2);
    const total_charger = props.evse_charger_status.total;
    const available_charger = props.evse_charger_status.available
    const out_of_service = props.evse_charger_status.out_of_service

    const Total_co2reduced_percentage = props.percentagechange && props.percentagechange.total_CO2_percentage
    const Total_co2reduced_change_symbol = props.percentagechange && props.percentagechange.total_CO2_change_symbol

    const Total_energy_percentage = props.percentagechange && props.percentagechange.total_energy_percentage
    const Total_energy_change_symbol = props.percentagechange && props.percentagechange.energy_change_symbol

    const Total_revenue_percentage = props.percentagechange && props.percentagechange.total_revenue_percentage
    const Total_revenue_change_symbol = props.percentagechange && props.percentagechange.revenue_change_symbol

    const Total_cleanenergy_percentage = props.percentagechange && props.percentagechange.energy_driven_percentage
    const Total_cleanenergy_symbol = props.percentagechange && props.percentagechange.energy_driven_change_symbol


    return (

        <Container fluid className='pb-4'>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mb-2">
                    <PureBreadcrumbs />
                </div>
                <div className="col-lg-6 col-sm-12 text-right">
                    <span className="text-magenta" style={{ fontSize: "10px" }}></span>&nbsp;&nbsp;
                    <a className="refresh" style={{ fontSize: "13px", color: "var(--button-color)", fontWeight: "bold", cursor: "pointer" }} onClick={handleAddClick}>Refresh &nbsp;<img style={{ height: "16px" }} src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'} alt="" /></a>
                </div>
            </div>

            {/* Intro Card(hero section) */}
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <Card className="herocard">
                        <Row className="mt-4">
                            <Col xs={12} md={6} style={{ zIndex: "99" }}>
                                <span className="heroheading">{t("Hello,")} {name}!</span>

                                <p className="herosubheading">
                                    {t('Check live charger data, track your consumption, revenue and generate insightful reports.')}
                                </p>

                                <p style={{ fontSize: "12px" }}>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/insightCharger.svg'} alt="" style={{ width: "14px" }} />
                                    &nbsp;&nbsp;{available_charger} {t("out of")} {total_charger} {t("chargers are Available.")} {out_of_service} {t('Chargers are Out of order and needs maintenance.')}
                                </p>
                                <p style={{ fontSize: "12px" }}>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/insightCurrent.svg'} alt="" style={{ width: "12px" }} />
                                    &nbsp;&nbsp; {t("You have reduced")}  {co2Data != undefined ? co2Data : 0} {t("kg of Co2 by utitlizing a total of")} {props.total_sesion.total_energy} {t("kWh of energy.")}
                                </p>
                            </Col>
                            <Col xs={12} md={6} className='d-flex justify-content-end'>
                                <img className="heroimage" src={process.env.PUBLIC_URL + '/images/insight/heroImage.svg'} alt="charging" />
                            </Col>
                        </Row>
                    </Card>
                </div>
                <div className="col-lg-1 col-sm-12">
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <span style={{ color: "var(--text-primary)", fontWeight: "600", fontSize: "22px" }}>{t('Insight')}</span>
                    <ChargerStatus />
                </div>

            </div>

            <div className="greenenergy pt-lg-5 pt-md-2">
                <span style={{ color: "var(--text-primary)", fontWeight: "600", fontSize: "22px" }}>{t('dashboard')}</span>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <a href='/dashboard/insight/co2ReductionList' style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <Card className='statuscard p-3'>
                                <span className="dashboardheader mb-3 text-center">{t('TOTAL CO2 REDUCED')}</span>
                                <h4 style={{ "fontSize": 15, "fontWeight": 600, textAlign: "center" }}>
                                    {co2Data !== undefined ? co2Data : 0}  <span title='Carbon dioxide'>{t("kg of Co2")}</span>
                                </h4>
                                <span style={{ fontSize: "12px", display: "flex", justifyContent: "center" }}>
                                    {Total_co2reduced_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                    &nbsp;&nbsp;{Total_co2reduced_percentage}% &nbsp;{t("change in last 30 days")}
                                </span>
                            </Card>
                        </a>
                        <br />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Card className='statuscard p-3'>
                            <span className="dashboardheader mb-3 text-center">{t('CLEAN ENERGY DRIVEN')}</span>
                            <h4 style={{ "fontSize": 15, "fontWeight": 600, textAlign: "center" }}>
                                {((props.total_sesion.total_energy * 1000) / 100).toFixed(2)} <span title='Kilo meters'>{t("KMs")}</span>
                            </h4>
                            <span style={{ fontSize: "12px", display: "flex", justifyContent: "center" }}>
                                {Total_cleanenergy_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_cleanenergy_percentage}% &nbsp;{t("change in last 30 days")}
                            </span>
                        </Card><br />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Card className='statuscard p-3'>
                            <span className="dashboardheader mb-3 text-center">{t('TOTAL ENERGY')}</span>
                            <h4 style={{ "fontSize": 15, "fontWeight": 600, textAlign: "center" }}>
                                {props.total_sesion.total_energy} <span title='Kilo watt hours'>{t("kWh")}</span>
                            </h4>
                            <span style={{ fontSize: "12px", display: "flex", justifyContent: "center" }}>
                                {Total_energy_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_energy_percentage}% &nbsp;{t("change in last 30 days")}
                            </span>
                        </Card><br />
                    </div>
                    {role !== 'Enterprise' ? <div className="col-lg-3 col-sm-6">
                        <Card className='statuscard p-3'>
                            <span className="dashboardheader mb-3 text-center">{t('TOTAL REVENUE')}</span>
                            <h4 style={{ "fontSize": 15, "fontWeight": 600, textAlign: "center" }}>
                                {currency} {props.total_sesion.total_revenue}
                            </h4>
                            <span style={{ fontSize: "12px", display: "flex", justifyContent: "center" }}>
                                {Total_revenue_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_revenue_percentage}% &nbsp;{t("change in last 30 days")}
                            </span>
                        </Card><br />
                    </div> : ''}
                </div>
            </div>

            <div className="row">
                 <div className="col-lg-5 col-sm-12 pad_t1">
                   <EnergyVSRevenueGraph />
                </div> 
                <div className="col-lg-7 col-sm-12 pad_t1">
                    {/* <UptimeChart {...props} /> */}
                    <Card className='statuscard'>
                        <Card.Header>{t('Latest Transaction')}</Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "340px",
                            }}>Loading data...</h3> :
                                props.all && props.all.data != null ? (
                                    <div>
                                        <InsightTable data={data} columns={columns}></InsightTable>
                                        <p className='mb-1 ml-2' >
                                            <Link className='view-more' to="/dashboard/report"><b>...{t("View More")}</b></Link>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "350px",
                                        }}
                                    >
                                        <h3>No Data Found</h3>
                                    </div>
                                )}

                        </div>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-sm-12 pad_t1">
                    <CityGraph />
                </div>
                <div className="col-lg-6 col-sm-12 pad_t1">
                    <StationGraph />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8 col-sm-12 pad_t1">
                    <SessionGraph />
                </div>
                <div className="col-lg-4 col-sm-12 pad_t1">
                    <SessionTypeGraph />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-sm-12 pad_t1">
                    <Card className='statuscard'>
                        <Card.Header>{t('Latest Failed Transaction')}</Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "340px",
                            }}>Loading data...</h3> :
                                props.latestFailedList && props.latestFailedList.data != null ? (
                                    <div>
                                        <InsightTable data={failedTransactionData} columns={failedTransactionColumns}></InsightTable>
                                        <p className='mb-1 ml-2' >
                                            <Link className='view-more' to="/dashboard/failed_report"><b>...{t("View More")}</b></Link>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "350px",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}

                        </div>
                    </Card>
                </div>

                <div className="col-lg-6 col-sm-12 pad_t1">
                    <Card className='statuscard'>
                        <Card.Header>{t('Latest Success Transaction')}</Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "340px",
                            }}>Loading data...</h3> :
                                props.latestSuccessList && props.latestSuccessList.data != null ? (
                                    <div>
                                        <InsightTable data={successTransactionData} columns={successTransactionReport}></InsightTable>
                                        <p className='mb-1 ml-2'>
                                            <Link className='view-more' to="/dashboard/success_report"><b>...{t("View More")}</b></Link>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "350px",
                                        }}
                                    >
                                        <h3>No Data Found</h3>
                                    </div>
                                )}

                        </div>
                    </Card>
                </div>
            </div>
        </Container>

    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        total_sesion: state.insight.charging_total_status,
        evse_charger_status: state.insight.evse_charger_status,
        percentagechange: state.insight.percentageChange,
        all: state.report.latestTransaction,
        latestFailedList: state.report.latestFailedTransaction,
        latestSuccessList: state.report.latestSuccessTransaction,
        fetchCo2Res: state.insight.fetchCo2Count,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getTotalCharging: () => dispatch(fetchChargingStatusAction()),
        getEvseStatus: () => dispatch(fetchEVSEStatusAction()),
        fetcPercentageChange: () => dispatch(insightPercentageChange()),
        list: () => dispatch(fetchTop10Transaction()),
        top10FailedList: () => dispatch(fetchTop10FailedTransaction()),
        top10SuccessList: () => dispatch(fetchTop10SuccessTransaction()),
        fetchNewCo2Count: () => dispatch(fetchCo2CountInsightAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Insight)