import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container } from "react-bootstrap";
import { saveRfidAction } from "../../../../store/actions/customerActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import { useTranslation } from 'react-i18next';
import "../evse.css";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { allEvseAction } from '../../../../store/actions/evseAction';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';
import Select from 'react-select'

const CreateRFID = (props) => {
    const { t } = useTranslation();
    const [isAmountBased, setIsAmountBased] = useState(false);
    const [isRechargeAmountFree, setIsRechargeAmountFree] = useState(false);
    const [iskWhBased, setIskWhBased] = useState(false);
    const [isTimeBased, setIsTimeBased] = useState(false);
    const [rfidRechargeAmount, setRfidRechargeAmount] = useState('');
    const [kWhUsage, setkWhUsage] = useState('');
    const [kWhAmount, setKWhAmount] = useState('');
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [time, setTime] = useState('');
    const [rfidType, setRfidType] = useState('');
    const [postpaidDuration, setPostpaidDuration] = useState('');
    const [timeRechargeAmount, setTimeRechargeAmount] = useState('');
    const [isPaymentCollectedOffline, setIsPaymentCollectedOffline] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const [selectedStations, setSelectedStations] = useState([]);

    useEffect(() => {
        props.getAllCurrency();
        props.all();
    }, []);

    const rfidSchema = Yup.object().shape({
        rfid: Yup.string().required('RFID serial number is required'),
        commission: Yup.string().required('Commission date is required').matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Commission date must be a valid date in the format DD-MM-YYYY'),
        currency: Yup.string().required('Currency is required'),
        station_name: Yup.array()
            .min(1, 'Please select at least one station name.')  // Require at least one selection
            .of(Yup.string().required('A station name is required.')),
    });

    const { register, handleSubmit, reset, setValue, getValues, watch, control, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(rfidSchema), defaultValues: {
            station_name: [],
        }, mode: "all"
    });

    const stationWatch = watch('station_name')
    // console.log("station watch", stationWatch)

    const onSubmit = async data => {

        const submissionData = {
            ...data,
            isAmountBased: isAmountBased,
            isRfidFreeCharger: isRechargeAmountFree,
            rfidRechargeAmount: isAmountBased ? rfidRechargeAmount : '',
            iskWhBased: iskWhBased,
            kWhUsage: iskWhBased ? kWhUsage : '',
            kWhAmount: kWhAmount,
            isTimeBased: isTimeBased,
            time: isTimeBased ? time : '',
            timeRechargeAmount: timeRechargeAmount,
            rfidType: rfidType,
            postpaidDuration: postpaidDuration,
            isPaymentCollectedOffline: isPaymentCollectedOffline,
        };
        // console.log("submissionData", submissionData);
        props.save(submissionData);
    };
    // console.log("isPaymentCollectedOffline", isPaymentCollectedOffline);

    const handleFreeCharge = () => {
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(!isRechargeAmountFree);
        if (!isRechargeAmountFree) {
            setRfidRechargeAmount('0');
            // setIsPaymentCollectedOffline(false)
        }
    }

    const handleAmountBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('');
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIsAmountBased(!isAmountBased);
        if (!isAmountBased) {
            setIskWhBased(false);
            setkWhUsage('');
            // setRfidType('')
        }
    };

    const HandleAmountBasedKeyPress = (event) => {
        // console.log("event", event);
        const { key, target: { value } } = event;
        const newValue = value + key; // simulate the new value after key press

        if (
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Tab'
        ) {
            return;
        }

        if (key === '.' && value === '') {
            event.preventDefault();
            // return;
        }
        if (key === '.' && value.includes('.')) {
            event.preventDefault();
            return;
        }

        // Prevent entering '0' initially or if the value is empty
        if (key === '0' && value === '') {
            event.preventDefault();
        }

        // Allow numbers and decimal point
        if (!/[0-9.]/.test(key)) {
            event.preventDefault();
        }
    };

    const handleKiloWattBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('')
        setRfidRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIskWhBased(!iskWhBased);
        if (!iskWhBased) {
            setIsAmountBased(false);
            setRfidRechargeAmount('');
            // setRfidType('')
        }
    };

    const handleTimeBasedChange = () => {
        // setRfidType('prepaid')
        setKWhAmount('')
        setRfidRechargeAmount('');
        setTimeRechargeAmount('');
        setIsRechargeAmountFree(false)
        setIsPaymentCollectedOffline(false)
        setIsTimeBased(!isTimeBased);
        if (!isTimeBased) {
            setTime('');
            // setRfidType('')
        }
    };

    const handleRfidTypeChange = (e) => {
        // setPostpaidDuration('');
        const value = e.target.value;
        setRfidType(value);
        // console.log("billing type", e.target.value);
    };

    const handleSubDuration = (e) => {
        const value = e.target.value;
        setPostpaidDuration(value);
    };

    const handleIsCollectedOffline = (event) => {
        const value = event.target.value === "true";
        setIsPaymentCollectedOffline(value);
        // console.log("Is payment collected offline?", value);
    };

    const isSubmitDisabled = () => {
        if (isAmountBased && (!rfidRechargeAmount)) {
            // console.log("1")
            return true;
        }
        if (iskWhBased && (!kWhUsage || !kWhAmount)) {
            // console.log("2")
            return true;
        }
        if (isTimeBased && (!time || !timeRechargeAmount)) {
            // console.log("3")
            return true;
        }
        if (!isRechargeAmountFree && !rfidType) {
            // console.log("4")
            return true;
        }
        if (rfidType === 'postpaid' && !postpaidDuration) {
            // console.log("5")
            return true;
        }
        if (isAmountBased && !isPaymentCollectedOffline) {
            // console.log("6")
            return false;
        }
        if ((iskWhBased || isTimeBased) && !isPaymentCollectedOffline) {
            // console.log("7")
            return true;
        }
        if (!isRechargeAmountFree) {
            // console.log("8")
            return false;
        }
        // console.log("No if condition is printed")

        return false;
    };

    useEffect(() => {
        if (props.response.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('RFID has been saved successfully.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.response.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ RFID Number has already been registered. Please choose a different number`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.response]);

    const handleCurrency = (e) => {
        const code = e.value;
        // console.log("currency", code);
        setValue("currency", code);
    };

    const currencyOptions = props.allcurrency && props.allcurrency.currency?.map(item => ({
        value: item.currency_code,
        label: item.currency_code
    }));

    // console.log("selected station names", selectedStations)
    // console.log("errors", errors)
    const stationNameOptions = [
        { value: 'All', label: 'All' }, // Static option
        ...(props.list && props.list.data ? props.list.data
            .filter((item) => item.status !== 'Discovery' && item.publish !== false)
            .map(item => ({
                value: item.name,
                label: item.name
            }))
            : []) // Dynamic options
    ];

    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Commission RFID")}</b></Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>{t("RFID")} <span className='mandatory-field'>*</span></label>
                                    <input type="text" autoFocus className={`form-control gray-border ${errors.rfid ? 'is-invalid' : ''}`} name="rfid" id="floatingInputGridrfid" placeholder={t("RFID")} {...register("rfid")} />
                                    <div className="invalid-feedback">{errors.rfid?.message}</div>
                                </div>

                                <div className="form-floating mt-3">
                                    <label>{t("Commission Date (dd/mm/yyyy)")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        type="date"
                                        className={`form-control gray-border ${errors.commission ? 'is-invalid' : ''}`}
                                        name="commission" id="floatingInputGridName"
                                        placeholder={t("Date")}
                                        {...register('commission', {
                                            required: 'Date is required',
                                            validate: {
                                                correctFormat: (value) => {
                                                    // This is a custom validator to check if the date format is correct.
                                                    const datePattern = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy format
                                                    return datePattern.test(value) || 'Invalid date format';
                                                },
                                                validDate: (value) => {
                                                    // Ensure the input is a valid date
                                                    return !isNaN(Date.parse(value)) || 'Invalid date';
                                                },
                                            },
                                        })}
                                        max={today}
                                    />
                                    <div className="invalid-feedback">{errors.commission?.message}</div>
                                </div>

                                <Row className="row d-flex align-items-center mt-4">
                                    <Col lg={4} className='mt-1'>
                                        <div className="">
                                            <input className="form-check-input ml-0" type="checkbox" id="amountBasedCheckbox" checked={isAmountBased} onChange={handleAmountBasedChange} disabled={iskWhBased || isTimeBased} />
                                            <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                {t("Amount Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="kiloWattBasedCheckbox" checked={iskWhBased} onChange={handleKiloWattBasedChange} disabled={isAmountBased || isTimeBased} />
                                            <label className="form-check-label" htmlFor="kiloWattBasedCheckbox">
                                                {t("kWh Based")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className='mt-1'>
                                        <div>
                                            <input className="form-check-input ml-0" type="checkbox" id="timeBasedCheckbox" checked={isTimeBased} onChange={handleTimeBasedChange} disabled={isAmountBased || iskWhBased} />
                                            <label className="form-check-label" htmlFor="timeBasedCheckbox">
                                                {t("Time Based")}
                                            </label>
                                        </div>
                                    </Col>
                                </Row><br />

                                {isAmountBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12 d-flex align-items-center'>
                                                {!isPaymentCollectedOffline &&
                                                    <div className="mt-3">
                                                        <input className="form-check-input" type="checkbox" id="amountBasedCheckbox" checked={isRechargeAmountFree} onChange={handleFreeCharge} />
                                                        <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                            {t("IsFreeCharge?")}
                                                        </label>
                                                    </div>}
                                            </div>
                                            <br />
                                            <div className={` ${isPaymentCollectedOffline ? "col-lg-12" : "col-lg-6"} col-md-12 mt-2`}>
                                                <div className="form-floating">
                                                    <label>{t("RFID Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!rfidRechargeAmount && 'is-invalid'}`}
                                                        name="rfidRechargeAmount"
                                                        id="floatingInputRfidRechargeAmount"
                                                        placeholder={t("RFID Recharge Amount")}
                                                        disabled={isRechargeAmountFree}
                                                        value={rfidRechargeAmount}
                                                        onKeyDown={HandleAmountBasedKeyPress}
                                                        onChange={(e) => setRfidRechargeAmount(e.target.value)}
                                                    />
                                                    {!rfidRechargeAmount && <small className="text-danger">{t("RFID Recharge Amount is required")}</small>}
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {!isRechargeAmountFree &&
                                            <div className='row'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} disabled={isRechargeAmountFree} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                            <option value="">{t("Select Yes/No")}</option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        {isPaymentCollectedOffline === null && <div className="invalid-feedback">{"This field is required"}</div>}
                                                    </div>
                                                </div>
                                            </div>}
                                    </>
                                )}

                                {iskWhBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("kWh Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!kWhUsage && 'is-invalid'}`}
                                                        name="kiloWattUsage"
                                                        id="floatingInputKiloWattUsage"
                                                        placeholder={t("kWh Usage")}
                                                        value={kWhUsage}
                                                        onChange={(e) => setkWhUsage(e.target.value)}
                                                        onKeyDown={HandleKeyPress}

                                                    />
                                                    {!kWhUsage && <small className="text-danger">{t("kWh Usage is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border ${!kWhAmount && 'is-invalid'}`}
                                                            name="kWhAmount"
                                                            id="floatingInputkWhAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={kWhAmount}
                                                            onChange={(e) => setKWhAmount(e.target.value)}
                                                            onKeyDown={HandleKeyPress}

                                                        />
                                                        {!kWhAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="">{t("Select Yes/No")}</option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {isPaymentCollectedOffline === null && <div className="invalid-feedback">{"This field is required"}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {isTimeBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Time")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border ${!time && 'is-invalid'}`}
                                                        name="time" id="floatingInputTime"
                                                        placeholder={t("No. of Hours")}
                                                        value={time}
                                                        onChange={(e) => setTime(e.target.value)}
                                                        onKeyDown={HandleKeyPress}

                                                    />
                                                    <small className="form-text text-muted">
                                                        {t("No. Of Hours")}
                                                    </small>
                                                    {!time && <small className="text-danger">{t("Time is required")}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number"
                                                        className={`form-control gray-border ${!timeRechargeAmount && 'is-invalid'}`}
                                                        name="RechargeAmount"
                                                        id="floatingInputRechargeAmount"
                                                        placeholder={t("Recharge Amount")}
                                                        value={timeRechargeAmount}
                                                        onChange={(e) => setTimeRechargeAmount(e.target.value)}
                                                        onKeyDown={HandleKeyPress}

                                                    />
                                                    {!timeRechargeAmount && <small className="text-danger">{t("Recharge Amount is required")}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <select className={`form-select form-control ${isPaymentCollectedOffline === null ? 'is-invalid' : ''}`} name="isPaymentCollectedOffline" id="floatingSelectGridisPaymentCollectedOffline" onChange={handleIsCollectedOffline} required>
                                                        <option value="">{t("Select Yes/No")}</option>
                                                        <option value={true}>{t("Yes")}</option>
                                                        <option value={false}>{t("No")}</option>
                                                    </select>
                                                    {isPaymentCollectedOffline === null && (
                                                        <div className="invalid-feedback">{"This field is required"}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='row mt-3'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Tariff Currency")} <span className='mandatory-field'>*</span></label>
                                            <Select
                                                options={currencyOptions ? currencyOptions : []}
                                                className={`${errors.currency ? 'is-invalid' : ''}`}
                                                name="currency"
                                                id="floatingInputGridcurrency"
                                                placeholder="Select Currency"
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'grey',
                                                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                                        minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                    }),
                                                    valueContainer: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                                        padding: '0 0.75rem',
                                                    }),
                                                    placeholder: (baseStyles) => ({
                                                        ...baseStyles,
                                                        whiteSpace: 'nowrap',
                                                    }),
                                                    input: base => ({
                                                        ...base,
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        '& input': {
                                                            border: 'none !important',
                                                        },
                                                    }),

                                                }}
                                                onChange={handleCurrency}
                                            />
                                            <div className="invalid-feedback">{errors.currency?.message}</div>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Select Station Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <Controller
                                                name="station_name"
                                                control={control}
                                                className={`${errors.station_name ? 'is-invalid' : ''}`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        options={stationNameOptions}
                                                        isMulti
                                                        placeholder="Select Station Name"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : 'grey',
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                            }),
                                                            placeholder: (baseStyles) => ({
                                                                ...baseStyles,
                                                                whiteSpace: 'nowrap',
                                                            }),
                                                            input: base => ({
                                                                ...base,
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                '& input': {
                                                                    border: 'none !important',
                                                                },
                                                            }),
                                                        }}
                                                        onChange={(selectedOptions) => {
                                                            onChange(selectedOptions ? selectedOptions.map(option => option?.value) : []);  // Update form value
                                                        }}
                                                        // value={stationNameOptions.filter(option => value?.includes(option.value))}
                                                        isOptionDisabled={(option) => {
                                                            const isAnyStationSelected = value && value.length > 0 && !value.includes('All');
                                                            const isAllSelected = value && value.includes('All');

                                                            // Disable "All" if any other station is selected
                                                            if (option.value === 'All') {
                                                                return isAnyStationSelected;
                                                            }

                                                            // Disable all other options if "All" is selected
                                                            return isAllSelected;
                                                        }}
                                                    />
                                                )}
                                            />

                                            {/* Display validation error message */}
                                            {errors.station_name && (
                                                <div className="invalid-feedback">
                                                    {errors.station_name.message}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                {!isRechargeAmountFree &&
                                    <div className='row mt-3'>
                                        <div className='col-md'>
                                            <div className="form-floating">
                                                <label>{t("RFID Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control`} name="billingType" id="floatingSelectGridCurrency" onChange={(e) => handleRfidTypeChange(e)} >
                                                    <option value="">{t("Select RFID Type")}</option>
                                                    <option value="prepaid">{t("Prepaid")}</option>
                                                    {(isAmountBased || iskWhBased || isTimeBased) && (
                                                        <option value="postpaid">{t("Postpaid")}</option>
                                                    )}
                                                </select>
                                                {rfidType === '' && <div className="invalid-feedback">{"RFID Type is required"}</div>}
                                            </div><br />
                                        </div>
                                        <div className={`${rfidType === 'postpaid' ? "col-md" : "col-12"}`}>
                                            {rfidType === 'postpaid' &&
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Postpaid Duration")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select form-control ${postpaidDuration === '' ? "is-invalid" : ""
                                                            }`}
                                                        name={`subscriptionDuration`}
                                                        id="floatingSelectGridsubscriptionDuration"
                                                        onChange={(e) => handleSubDuration(e)}
                                                    >
                                                        <option value={""}>
                                                            {t("Select Postpaid Duration")}
                                                        </option>
                                                        <option value={"weekly"}>{t("Weekly")}</option>
                                                        <option value={"monthly"}>{t("Monthly")}</option>
                                                        <option value={"yearly"}>{t("Yearly")}</option>
                                                    </select>
                                                    {postpaidDuration === '' && <div className="invalid-feedback">{"Postpaid Duration is required"}</div>}
                                                </div>
                                            }
                                        </div>
                                    </div>}

                                <Row className='mt-3'>
                                    <Col className='col-md mt-2'>
                                        <button className="lgn-btn btn" type="button" onClick={() => window.location.reload()}> {t("Reset")} </button>
                                    </Col>
                                    <Col className='col-md mt-2 d-md-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid && isSubmitDisabled()}>{t("SAVE RFID")}</button>
                                        }
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container >
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        response: state.customer.saveRfid,
        allcurrency: state.tariff.fetchcurrency,
        list: state.evse.allevse,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        save: (data) => dispatch(saveRfidAction(data)),
        getAllCurrency: () => dispatch(allCurrencyAction()),
        all: () => dispatch(allEvseAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRFID);
